exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cabin-atardecer-js": () => import("./../../../src/pages/cabin/atardecer.js" /* webpackChunkName: "component---src-pages-cabin-atardecer-js" */),
  "component---src-pages-cabin-choose-js": () => import("./../../../src/pages/cabin/choose.js" /* webpackChunkName: "component---src-pages-cabin-choose-js" */),
  "component---src-pages-cabin-clima-js": () => import("./../../../src/pages/cabin/clima.js" /* webpackChunkName: "component---src-pages-cabin-clima-js" */),
  "component---src-pages-cabin-gaviota-js": () => import("./../../../src/pages/cabin/gaviota.js" /* webpackChunkName: "component---src-pages-cabin-gaviota-js" */),
  "component---src-pages-cabin-habitaciones-js": () => import("./../../../src/pages/cabin/habitaciones.js" /* webpackChunkName: "component---src-pages-cabin-habitaciones-js" */),
  "component---src-pages-cabin-perla-js": () => import("./../../../src/pages/cabin/perla.js" /* webpackChunkName: "component---src-pages-cabin-perla-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

